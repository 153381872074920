import {IProductDTO} from '../types/app-types';
import {isVideo} from '@wix/wixstores-client-core/dist/src/media/mediaService';
import {WIXVIDEO_BASEURL} from '../constants';

export type VideoSeoData = {
  videoThumbnailUrl: string;
  videoDescription: string;
  videoContentUrl: string;
}[];

export function getVideoSeoData(product: IProductDTO): VideoSeoData {
  return product.media.filter(isVideo).map((media) => {
    return {
      videoThumbnailUrl: media.thumbnailFullUrl,
      videoDescription: media.altText,
      videoName: product.name,
      videoContentUrl: `${WIXVIDEO_BASEURL}${media.videoFiles[0].url}`,
    };
  });
}
